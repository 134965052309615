const pagesAndSitemapConfig: PagesAndSitemapConfig = {
  index: {
    'en-us': '/',
    'de-de': '/',
    is: '/',
    'sv-se': '/',
    'es-es': '/',
    'fr-fr': '/',
    'it-it': '/',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  '[slug]/index': {
    'en-us': '/[slug]',
    'de-de': '/[slug]',
    is: '/[slug]',
    'sv-se': '/[slug]',
    'es-es': '/[slug]',
    'fr-fr': '/[slug]',
    'it-it': '/[slug]',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: true,
  },
  'contact-us/index': {
    'en-us': '/contact-us',
    'de-de': '/kontakt',
    is: '/hafa-samband',
    'sv-se': '/kontakta-oss',
    'es-es': '/contacta-con-nosotros',
    'fr-fr': '/contactez-nous',
    'it-it': '/contattaci',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'book-a-demo/index': {
    'en-us': '/book-a-demo',
    'de-de': '/vorfuehrung-buchen',
    is: '/boka-fund',
    'sv-se': '/boka-en-demonstration',
    'es-es': '/reserva-una-demo',
    'fr-fr': '/reservez-une-demo',
    'it-it': '/prenota-una-demo',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'partnerships/index': {
    'en-us': '/partnerships',
    'de-de': '/partnerschaften',
    is: '/samstarf',
    'sv-se': '/partnerskap',
    'es-es': '/asociaciones',
    'fr-fr': '/partenariats',
    'it-it': '/partenariati',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'link-in-bio/index': {
    'en-us': '/link-in-bio',
    'de-de': '/link-in-bio',
    is: '/link-in-bio',
    'sv-se': '/link-in-bio',
    'es-es': '/link-in-bio',
    'fr-fr': '/link-in-bio',
    'it-it': '/link-in-bio',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: false,
  },
  'faq/index': {
    'en-us': '/faq',
    'de-de': '/fragen-und-antworten',
    is: '/spurningar-og-svor',
    'sv-se': '/vanliga-fragor',
    'es-es': '/preguntas-frecuentes',
    'fr-fr': '/foire-aux-questions',
    'it-it': '/domande-frequenti',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'cookie-policy/index': {
    'en-us': '/cookie-policy',
    'de-de': '/cookie-richtlinie',
    is: '/vafrakokustefna',
    'sv-se': '/cookiepolicy',
    'es-es': '/politica-de-cookies',
    'fr-fr': '/politique-cookies',
    'it-it': '/informativa-cookie',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: false,
  },
  'newsroom/index': {
    'en-us': '/newsroom',
    'de-de': '/nachrichten',
    is: '/frettir',
    'sv-se': '/nyhetsrummet',
    'es-es': '/sala-de-prensa',
    'fr-fr': '/espace-presse',
    'it-it': '/area-stampa',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'newsroom/[slug]/index': {
    'en-us': '/newsroom/[slug]',
    'de-de': '/nachrichten/[slug]',
    is: '/frettir/[slug]',
    'sv-se': '/nyhetsrummet/[slug]',
    'es-es': '/sala-de-prensa/[slug]',
    'fr-fr': '/espace-presse/[slug]',
    'it-it': '/area-stampa/[slug]',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: true,
  },
  'successfully-booked-a-demo/index': {
    'en-us': '/successfully-booked-a-demo',
    'de-de': '/successfully-booked-a-demo',
    is: '/successfully-booked-a-demo',
    'sv-se': '/successfully-booked-a-demo',
    'es-es': '/successfully-booked-a-demo',
    'fr-fr': '/successfully-booked-a-demo',
    'it-it': '/successfully-booked-a-demo',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: false,
  },
  'privacy-policy/index': {
    'en-us': '/privacy-policy',
    'de-de': '/datenschutzrichtlinie',
    is: '/personuverndarskilmalar',
    'sv-se': '/sekretesspolicy',
    'es-es': '/politica-de-privacidad',
    'fr-fr': '/politique-de-confidentialite',
    'it-it': '/informativa-privacy',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: false,
  },
  'product/index': {
    'en-us': '/product',
    'de-de': '/produkt',
    is: '/hugbunadur',
    'sv-se': '/produkten',
    'es-es': '/producto',
    'fr-fr': '/produit',
    'it-it': '/prodotto',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'product/compensation-assistant/index': {
    'en-us': '/product/compensation-assistant',
    'de-de': '/produkt/verguetungsassistent',
    is: '/hugbunadur/launaradgjafi',
    'sv-se': '/produkten/loeneassistent',
    'es-es': '/producto/asistente-de-remuneracion',
    'fr-fr': '/produit/assistant-de-remuneration',
    'it-it': '/prodotto/assistente-retribuzioni',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'product/job-evaluation/index': {
    'en-us': '/product/job-evaluation',
    'de-de': '/produkt/stellenbewertung',
    is: '/hugbunadur/starfsmat',
    'sv-se': '/produkten/arbetsvaerdering',
    'es-es': '/producto/valoracion-de-los-puestos-de-trabajo',
    'fr-fr': '/produit/evaluation-des-emplois',
    'it-it': '/prodotto/valutazione-del-lavoro',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'product/reports/index': {
    'en-us': '/product/reports',
    'de-de': '/produkt/berichte',
    is: '/hugbunadur/skyrslur',
    'sv-se': '/produkten/rapporter',
    'es-es': '/producto/informes',
    'fr-fr': '/produit/rapports',
    'it-it': '/prodotto/report',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'product/user-access-controls/index': {
    'en-us': '/product/user-access-controls',
    'de-de': '/produkt/benutzerzugriffskontrollen',
    is: '/hugbunadur/adgangsstyringar',
    'sv-se': '/produkten/styrning-av-anvaendaratkomst',
    'es-es': '/producto/control-de-acceso-de-los-usuarios',
    'fr-fr': '/produit/controles-dacces-des-utilisateurs',
    'it-it': '/prodotto/controlli-degli-accessi-utente',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'product/workforce-analytics/index': {
    'en-us': '/product/workforce-analytics',
    'de-de': '/produkt/personalanalyse',
    is: '/hugbunadur/jafnrettisvisar',
    'sv-se': '/produkten/personalanalys',
    'es-es': '/producto/analisis-del-personal',
    'fr-fr': '/produit/analyse-du-personnel',
    'it-it': '/prodotto/analisi-del-personale',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'product/software-security/index': {
    'en-us': '/product/software-security',
    'de-de': '/produkt/software-sicherheit',
    is: '/hugbunadur/oeryggi-hugbunadarins',
    'sv-se': '/produkten/programvarusaekerhet',
    'es-es': '/producto/seguridad-del-software',
    'fr-fr': '/produit/securite-du-logiciel',
    'it-it': '/prodotto/sicurezza-del-software',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'product/data-management/index': {
    'en-us': '/product/data-management',
    'de-de': '/produkt/datenmanagement',
    is: '/hugbunadur/gagnastjornun',
    'sv-se': '/produkten/hantering-av-data',
    'es-es': '/producto/gestion-de-los-datos',
    'fr-fr': '/produit/gestion-des-donnees',
    'it-it': '/prodotto/gestione-dei-dati',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'product/pay-equity-analysis/index': {
    'en-us': '/product/pay-equity-analysis',
    'de-de': '/produkt/analyse-der-lohngleichheit',
    is: '/hugbunadur/launagreiningar',
    'sv-se': '/produkten/raettvisa-loener-analys',
    'es-es': '/producto/analisis-de-igualdad-salarial',
    'fr-fr': '/produit/analyse-de-l-equite-salariale',
    'it-it': '/prodotto/analisi-dell-equita-retributiva',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'product/value-based-comparison/index': {
    'en-us': '/product/value-based-comparison',
    'de-de': '/produkt/wertbasierter-vergleich',
    is: '/hugbunadur/virdismidadur-samanburdur',
    'sv-se': '/produkten/loenekartlaeggning',
    'es-es': '/producto/comparacion-basada-en-el-valor',
    'fr-fr': '/produit/comparaison-selon-la-valeur',
    'it-it': '/prodotto/confronto-basato-sul-valore',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'product/remediation-actions/index': {
    'en-us': '/product/remediation-actions',
    'de-de': '/produkt/abhilfemassnahmen',
    is: '/hugbunadur/haekkunartilloegur',
    'sv-se': '/produkten/avhjaelpande-atgaerder',
    'es-es': '/producto/medidas-de-solucion',
    'fr-fr': '/produit/recommandations-correctives',
    'it-it': '/prodotto/azioni-di-riparazione',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'coffee-talk/index': {
    'en-us': '/coffee-talk',
    'de-de': '/coffee-talk',
    is: '/coffee-talk',
    'sv-se': '/coffee-talk',
    'es-es': '/coffee-talk',
    'fr-fr': '/coffee-talk',
    'it-it': '/coffee-talk',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'coffee-talk/[slug]/index': {
    'en-us': '/coffee-talk/[slug]',
    'de-de': '/coffee-talk/[slug]',
    is: '/coffee-talk/[slug]',
    'sv-se': '/coffee-talk/[slug]',
    'es-es': '/coffee-talk/[slug]',
    'fr-fr': '/coffee-talk/[slug]',
    'it-it': '/coffee-talk/[slug]',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: true,
  },
  'resources/index': {
    'en-us': '/resources',
    'de-de': '/ressourcen',
    is: '/fraedsluefni',
    'sv-se': '/resurser',
    'es-es': '/recursos',
    'fr-fr': '/ressources',
    'it-it': '/risorse',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'resources/local-requirements/index': {
    'en-us': '/resources/local-requirements',
    'de-de': '/ressourcen/lokale-anforderungen',
    is: '/fraedsluefni/stadbundnar-krofur',
    'sv-se': '/resurser/lokala-krav',
    'es-es': '/recursos/requisitos-locales',
    'fr-fr': '/ressources/exigences-locales',
    'it-it': '/risorse/requisiti-locali',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'resources/local-requirements/[slug]/index': {
    'en-us': '/resources/local-requirements/[slug]',
    'de-de': '/ressourcen/lokale-anforderungen/[slug]',
    is: '/fraedsluefni/stadbundnar-krofur/[slug]',
    'sv-se': '/resurser/lokala-krav/[slug]',
    'es-es': '/recursos/requisitos-locales/[slug]',
    'fr-fr': '/ressources/exigences-locales/[slug]',
    'it-it': '/risorse/requisiti-locali/[slug]',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: true,
  },
  'resources/guides/index': {
    'en-us': '/resources/guides',
    'de-de': '/ressourcen/guides',
    is: '/fraedsluefni/leidarvisar',
    'sv-se': '/resurser/guider',
    'es-es': '/recursos/guias',
    'fr-fr': '/ressources/guides',
    'it-it': '/risorse/guide',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'resources/guides/[slug]/index': {
    'en-us': '/resources/guides/[slug]',
    'de-de': '/ressourcen/guides/[slug]',
    is: '/fraedsluefni/leidarvisar/[slug]',
    'sv-se': '/resurser/guider/[slug]',
    'es-es': '/recursos/guias/[slug]',
    'fr-fr': '/ressources/guides/[slug]',
    'it-it': '/risorse/guide/[slug]',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: true,
  },
  'resources/customer-success/index': {
    'en-us': '/resources/customer-success',
    'de-de': '/ressourcen/kundenerfolg',
    is: '/fraedsluefni/arangur-vidskiptavina',
    'sv-se': '/resurser/kundframgang',
    'es-es': '/recursos/exito-del-cliente',
    'fr-fr': '/ressources/succes-client',
    'it-it': '/risorse/successo-clienti',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'resources/customer-success/[slug]/index': {
    'en-us': '/resources/customer-success/[slug]',
    'de-de': '/ressourcen/kundenerfolg/[slug]',
    is: '/fraedsluefni/arangur-vidskiptavina/[slug]',
    'sv-se': '/resurser/kundframgang/[slug]',
    'es-es': '/recursos/exito-del-cliente/[slug]',
    'fr-fr': '/ressources/succes-client/[slug]',
    'it-it': '/risorse/successo-clienti/[slug]',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: true,
  },
  'resources/articles/index': {
    'en-us': '/resources/articles',
    'de-de': '/ressourcen/artikel',
    is: '/fraedsluefni/greinar',
    'sv-se': '/resurser/artiklar',
    'es-es': '/recursos/articulos',
    'fr-fr': '/ressources/articles',
    'it-it': '/risorse/articoli',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'resources/articles/[slug]/index': {
    'en-us': '/resources/articles/[slug]',
    'de-de': '/ressourcen/artikel/[slug]',
    is: '/fraedsluefni/greinar/[slug]',
    'sv-se': '/resurser/artiklar/[slug]',
    'es-es': '/recursos/articulos/[slug]',
    'fr-fr': '/ressources/articles/[slug]',
    'it-it': '/risorse/articoli/[slug]',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: true,
  },
  'company/index': {
    'en-us': '/company',
    'de-de': '/unternehmen',
    is: '/fyrirtaekid',
    'sv-se': '/foretag',
    'es-es': '/empresa',
    'fr-fr': '/entreprise',
    'it-it': '/azienda',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'careers/index': {
    'en-us': '/careers',
    'de-de': '/jobs',
    is: '/storf',
    'sv-se': '/karriar',
    'es-es': '/carreras-profesionales',
    'fr-fr': '/offres-emploi',
    'it-it': '/carriere',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'careers/[slug]/index': {
    'en-us': '/careers/[slug]',
    'de-de': '/jobs/[slug]',
    is: '/storf/[slug]',
    'sv-se': '/karriar/[slug]',
    'es-es': '/carreras-profesionales/[slug]',
    'fr-fr': '/offres-emploi/[slug]',
    'it-it': '/carriere/[slug]',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: true,
  },
  'trust-center/index': {
    'en-us': '/trust-center',
    'de-de': '/trust-center',
    is: '/trust-center',
    'sv-se': '/trust-center',
    'es-es': '/trust-center',
    'fr-fr': '/trust-center',
    'it-it': '/trust-center',
    langsExcludedFromSitemap: ['de-de', 'is', 'sv-se', 'es-es', 'fr-fr', 'it-it'],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'trust-center/[slug]/index': {
    'en-us': '/trust-center/[slug]',
    'de-de': '/trust-center/[slug]',
    is: '/trust-center/[slug]',
    'sv-se': '/trust-center/[slug]',
    'es-es': '/trust-center/[slug]',
    'fr-fr': '/trust-center/[slug]',
    'it-it': '/trust-center/[slug]',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: false,
  },
  'terms-and-conditions/index': {
    'en-us': '/terms-and-conditions',
    'de-de': '/agb',
    is: '/skilmalar',
    'sv-se': '/villkor',
    'es-es': '/terminos-y-condiciones',
    'fr-fr': '/termes-et-conditions',
    'it-it': '/termini-e-condizioni',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: false,
  },
  'newsletter/index': {
    'en-us': '/newsletter',
    'de-de': '/email-verteiler',
    is: '/frettabref',
    'sv-se': '/sandlista',
    'es-es': '/boletin-informativo',
    'fr-fr': '/bulletin',
    'it-it': '/newsletter',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'brand/index': {
    'en-us': '/brand',
    'de-de': '/marken-assets',
    is: '/vorumerki',
    'sv-se': '/varumarkestillgangar',
    'es-es': '/marca',
    'fr-fr': '/marque',
    'it-it': '/marchio',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: true,
    shouldBeIndexed: true,
  },
  'seo/index': {
    'en-us': '/seo',
    'de-de': '/seo',
    is: '/seo',
    'sv-se': '/seo',
    'es-es': '/seo',
    'fr-fr': '/seo',
    'it-it': '/seo',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: false,
  },
  'seo/pages-structure/index': {
    'en-us': '/seo/pages-structure',
    'de-de': '/seo/pages-structure',
    is: '/seo/pages-structure',
    'sv-se': '/seo/pages-structure',
    'es-es': '/seo/pages-structure',
    'fr-fr': '/seo/pages-structure',
    'it-it': '/seo/pages-structure',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: false,
  },
  'seo/all-articles/index': {
    'en-us': '/seo/all-articles',
    'de-de': '/seo/all-articles',
    is: '/seo/all-articles',
    'sv-se': '/seo/all-articles',
    'es-es': '/seo/all-articles',
    'fr-fr': '/seo/all-articles',
    'it-it': '/seo/all-articles',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: false,
  },
  'seo/metadata/index': {
    'en-us': '/seo/metadata',
    'de-de': '/seo/metadata',
    is: '/seo/metadata',
    'sv-se': '/seo/metadata',
    'es-es': '/seo/metadata',
    'fr-fr': '/seo/metadata',
    'it-it': '/seo/metadata',
    langsExcludedFromSitemap: [],
    isPartOfSitemap: false,
    shouldBeIndexed: false,
  },
}

const pageKeys = Object.keys(pagesAndSitemapConfig) as WebsitePageI18nKey[]
const i18nPagesObj = {} as PagesConfig
const i18nPagesSitemapConfigObj = {} as SitemapConfig

for (const key of pageKeys) {
  const { langsExcludedFromSitemap, isPartOfSitemap, shouldBeIndexed, ...langs } =
    pagesAndSitemapConfig[key]
  i18nPagesObj[key] = {
    ...langs,
  }
  i18nPagesSitemapConfigObj[key] = {
    langsExcludedFromSitemap,
    isPartOfSitemap,
    shouldBeIndexed,
  }
}

// We need to split these up since i18n does not expect any keys other than language names for the pages object (i18nPages).
export const i18nPages = i18nPagesObj
export const i18nPagesSitemapConfig = i18nPagesSitemapConfigObj
